import React from 'react';

const PostLandingStatsTreeView: React.FC = () => {
  return (
    <>
      <div className="post-landing-stats__tree-container">
        <svg className="post-landing-stats__tree" viewBox="0 0 618 169" fill="none">
          <path
            d="M7.3 158.488v-52.01c0-9.687 7.866-17.53 17.582-17.53h572.291c7.461 0 13.528 6.032 13.528 13.487v62.522M206.243 88.947l.568 69.541M409.567 158.488V89.643M309 88.947V14.555"
            stroke="#232323"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M7.3 164.957c3.583 0 6.487-2.896 6.487-6.469 0-3.573-2.904-6.469-6.488-6.469-3.583 0-6.488 2.896-6.488 6.469 0 3.573 2.905 6.469 6.488 6.469zM206.811 166.574c3.583 0 6.488-2.896 6.488-6.469 0-3.572-2.905-6.469-6.488-6.469-3.583 0-6.488 2.897-6.488 6.469 0 3.573 2.905 6.469 6.488 6.469zM409.567 168.191c3.583 0 6.488-2.896 6.488-6.469 0-3.572-2.905-6.468-6.488-6.468-3.583 0-6.488 2.896-6.488 6.468 0 3.573 2.905 6.469 6.488 6.469zM610.701 166.574c3.583 0 6.488-2.896 6.488-6.469 0-3.572-2.905-6.469-6.488-6.469-3.584 0-6.488 2.897-6.488 6.469 0 3.573 2.904 6.469 6.488 6.469z"
            fill="#191919"
            stroke="#232323"
            strokeMiterlimit="10"
          />
          <path
            d="M309 14.555c3.583 0 6.488-2.896 6.488-6.469 0-3.573-2.905-6.469-6.488-6.469-3.583 0-6.488 2.896-6.488 6.47 0 3.572 2.905 6.468 6.488 6.468z"
            stroke="#232323"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </>
  );
};

export default PostLandingStatsTreeView;

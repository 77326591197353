import React from 'react';
import { ReactComponent as Miniscus } from './miniscus.svg';

const PhotosBackdropView: React.FC = () => {
  return (
    <>
      <Miniscus />
    </>
  );
};

export default PhotosBackdropView;
